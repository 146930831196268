@import "../../_variables.scss";

.dialog-box {
  .alert-dialog-title {
    font-size: 1rem;
    color: $color-secondary;
  }
.close-icon{
  float:right;
  margin-left: auto;
  
}
  .alert-dialog-description {
    font-size: 0.875rem;
    color: $color-secondary
  }

  .alert-cancel {
    margin-top: 1rem;
    border: 0.063rem solid $color-primary;
    color: $color-primary;
    background-color: $color-white;
    padding: 0.469rem 2rem;
    border-radius: 0.3125rem;
    text-transform: none;
    &:hover {
      border: 0.063rem solid $color-primary;
    }
    &:active, &:focus, &:focus-visible, &:focus-within{
      background-color: $color-white;
    }
  }

  .alert-submit {
    margin-top: 1rem;
    padding: 0.469rem 2rem;
    border-radius: 0.3125rem;
    color: $color-white;
    background-color: $color-primary;
    text-transform: none;

    &.disabled{
      color: $color-white;
      background: $color-primary;
      opacity: 0.5;
    }
    &:hover {
      background-color: $color-primary;
    }
  }
}