@import "../../../../variables.scss";

.autocontrol-type-drodown-modal-main{
    .MuiBackdrop-root {
        background-color: rgb(52, 56, 60, 0.25);
    }
    .MuiPaper-root {
        ul.MuiList-root{
            min-height: 8.5rem;
            background: $color-white;
            border: 0.063rem solid $color-gray;
            border-radius: 0.188rem;
            padding: 0.25rem 0.25rem 0 0.25rem;
            height: 6.4rem;
            -ms-overflow-style: none;
            scrollbar-width: none;
            width:2rem;
            &::-webkit-scrollbar {
                display: none;
            }

            li.MuiMenuItem-root{
                font-weight: 500;
                font-size: 0.813rem;
                line-height: 1.375rem;
                color: $color-secondary;
                text-align: center;
                justify-content: center;
                padding: 0;
                margin: 0;

                &:hover, &.ite-active{
                    background: $color-light-gray;
                }
                &.ite-inactive{
                    background: $color-white;
                }
            }
            li.switch-btn{
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 0.875rem;
                text-align: center;
                color: $color-black;
                background: $color-white;
                border-top: 0.063rem solid $color-gray;
                padding: 0.313rem 0;
                position: sticky;
                bottom: 0;
                &:hover{
                    background: $color-white;
                }
                .MuiSwitch-root{
                    margin: -0.438rem;
                    padding-right: 0.725rem;
                    width: 2.188rem;
                    height: 1.313rem;

                    .MuiSwitch-switchBase.Mui-checked {
                        -webkit-transform: translateX(0.625rem);
                        -moz-transform: translateX(0.625rem);
                        -ms-transform: translateX(0.625rem);
                        transform: translateX(0.625rem);

                        .MuiSwitch-thumb {
                            border: 0.063rem solid transparent;
                        }
                    }

                    .MuiSwitch-switchBase {
                        .MuiSwitch-thumb {
                            width: 0.625rem;
                            height: 0.625rem;
                            border: 0.063rem solid $color-gray;
                        }
                    }
                    
                    .MuiSwitch-track{
                        background: $color-gray;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
