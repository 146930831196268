@import "../../../_variables.scss";

.MuiMenu-root.manual-markup-modal {
  .MuiBackdrop-root {
    background-color: rgb(52, 56, 60, 0.25);
  }
  .MuiPaper-root {
    background: $color-white;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 5px 5px;
    
    ul.MuiMenu-list {
      background: $color-white;
      padding: 8px 0 0;
      display: inline-block;
      position: relative;
      
      li.MuiMenuItem-root {
        width: 24px;
        height: 24px;
        border-radius: 50px;
        background: $color-gray;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin: 0 4px 8px 4px;
        color:$color-black;
        &:hover  {
          border: 1px solid $red;
          fill:$red
        }
        img {
          width: 100%;
        }
      }
      li.MuiMenuItem-root.active{
        background-color: $red;
      }
    }
  }
}
