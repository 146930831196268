@import "../../../_variables.scss";


.page-title {
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.016rem;
    padding: 0 1.5rem;
    margin-bottom: 0rem;
    margin-top: 1.5rem;
    line-height: 1.75rem;
    color: $color-black;
}

.workup-root {
    display: flex;
    margin-bottom: 0.635rem;
    padding: 1rem 1.5rem 0 1.5rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    .left-sec {
        .MuiTextField-root.search {
            .MuiOutlinedInput-root {
                border-radius: 3.125rem;
                width: 25rem;
            }

            .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: 0.063rem solid $color-gray;
                }
            }

        }
    }

    .right-sec {
        display: flex;
        margin-left: auto;

        .filter-right-sec {
            margin-right: 0.625rem;

            .MuiButtonBase-root {
                background-color: $color-white;
                color: $color-primary;
                border: 0.063rem solid $color-primary;
                &:hover{
                    background-color: $color-primary-light;
                }
            }
        }

        .MuiButtonBase-root {
            background-color: $color-primary;
            color: $color-white;
            font-size: 0.875rem;
            line-height: 1.125rem;
            font-weight: 500;
            padding: 0.813rem 2rem;
            border-radius: 0.313rem;
            text-transform: none;
            cursor: pointer;
            border: none;
        }
    }
}

.workup-table-root {
    padding: 0 1.5rem;

    .MuiBox-root {
        .table-container {

            table.table {
                border: 0.063rem solid $color-light-gray;
                border-radius: 1rem;

                tr {
                    th:first-child{
                        width: 1rem;
                    }
                    th:last-child{
                        width: 1rem;
                    }
                    th.MuiTableCell-root {
                        padding: 0.556rem 0.75rem;
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        background: $color-light-gray;
                        border-bottom: 0.063rem solid $color-medium-gray;
                        max-width: 15rem;
                        
                        .table-col-heading-wrapeer {
                            display: flex;
                            align-items: center;

                        }

                        .table-sorting-icons {
                            display: inline-grid;
                            float: right;
                            margin-left: 0.25rem;
                            cursor: pointer;

                            :first-child {
                                margin-bottom: 0.25rem;
                            }
                        }

                        .red {
                            path {
                                fill: $color-primary;
                            }
                        }
                    }

                    &.selected {
                        background-color: $color-blue-gray;
                    }

                    &.highlighted{
                        background-color: $color-blue-gray;
                    }
                    td.MuiTableCell-root {
                        padding: 0.156rem 0.75rem;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        border-bottom: 0.063rem solid $color-medium-gray;
                        max-width: 15rem;

                        .expand-icon-wrapper svg {
                            fill: $color-secondary;
                        }

                    }

                    a {
                        color: $color-primary;
                        text-decoration-color: $color-primary;
                        font-weight: 500;
                        display: flex;

                        div {
                            white-space: nowrap;
                            max-width: 6rem;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .status{ 
                    span{
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    letter-spacing: 0.02rem;
                    text-transform: uppercase;
                    padding: 0.25rem 0.5rem;
                    border-radius: 62.438rem;
                }
                .new{
                    background-color: $color-info-light;
                }
                .in-progress{
                    background-color: $color-warning;
                }
                .in-review{
                    background-color: $color-rating;
                }
                .completed{
                    background-color: $color-success;
                }
            }
                .expand-row-container {
                    background-color: $color-light-gray;

                    .MuiTableCell-sizeSmall {
                        border-bottom: none;
                    }
                  
                    #expanded-workup-details{
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        width: 20%;
                        padding-left: 1.563rem;
                    }
                    #expanded-label-details{
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        padding-bottom: 0.25rem;
                        padding-top: 1.188rem;
                        color: $color-dark-gray;
                        width: 20%;
                        padding-left: 1.563rem;
                        
                    }

                    .panel-details{
                        padding-bottom: 1.281rem;     
                    }

                    

                }
            }
        }
    }


}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.313rem;
    border-radius: 0.313rem;
    padding: 1rem 0rem;

    .cancel {
        padding: 1.1813rem 2rem;
        background: $color-white;
        border: 0.063rem solid $color-white;
        color: $color-primary;
        cursor: pointer;
    }

    button:disabled {
        background-color: $color-primary;
        color: $color-white ;
    }

    .submit {
        background-color: $color-primary;
        color: $color-white ;
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 500;
        padding: 0.813rem 2rem;
        border-radius: 0.313rem;
        text-transform: none;
        cursor: pointer;
        &:hover{
            background-color: $color-primary-dark;
          }
    }
}



.patient.MuiMenu-root.MuiMenu-root .MuiPaper-root {
    justify-content: end;
    background: $color-white;
    box-shadow: 0rem 0.125rem 0.438 rgb(0 0 0 / 10%);
    border-radius: 0.25rem;
    width: 10rem;
    right: 5%;


}

.MuiMenu-root .MuiPaper-root ul.MuiMenu-list li.MuiMenuItem-root {

    background-color: $color-white;
    text-align: center;
    align-items: center;
    padding: 0.313rem;
    margin: 0 0.25rem 0.5rem 0.5rem;

    a {
        text-decoration: none;
        color: inherit
    }
}

.btn-disabled {
    opacity: 0.25;
}

.hightlighted-row {
    background-color: green;
}

@media screen and (min-width: 1441px) and (max-width: 1660px){     
    .workup-root{
        padding: 1.5rem 1.5rem 0 1.5rem;
    }
}
@media screen and (min-width: 1661px){     
    .workup-root{
        padding: 1.5rem 1.5rem 0 1.5rem;
    }
}

.workup-edit-success-message{
    box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 1.063rem 2rem 0rem 1.188rem;
  align-items: flex-start;
  gap: 0.625rem;
  position: fixed;
  width: 24.5rem;
  height: 5rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 5.25rem;
  background: $color-sucess-secondary-light;
  border: 0.063rem solid $color-sucess-secondary;
  box-shadow: 0rem 0.5rem 1rem rgba(39, 40, 51, 0.2);
  border-radius: 0.25rem;
  z-index: 99;
  .main-content{
    margin-left: 0.375rem;
  .bold-content{
    font-weight: 500;
  }
  .normal-content{
    margin-left: 0.25rem;
  }

}
.cross-mark{
    margin-left: auto;
  }
  
}

@media screen and (max-width:1280px){
    .workup-table-root {   
        .MuiBox-root {   
            .table-container {   
                table.table {   
                    tr {
                        a {
                            div {
                                display: table;
                            }
                        }
                    }  
                }
            }
        }
    }
}
@media screen and (max-width:1200px){
    .workup-root {  
        .left-sec {
            .MuiTextField-root.search {
                .MuiOutlinedInput-root {
                    width: auto;
                }
            }
        }
    }
    
}